import React, { useEffect, useCallback, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import Box from "@mui/material/Box";
import { java } from "@codemirror/lang-java";
import { historyField } from "@codemirror/commands";
import { indentOnInput } from "@codemirror/language";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { EditorView, Decoration, keymap } from "@codemirror/view";
import { StateField, StateEffect } from "@codemirror/state";

const stateFields = { history: historyField };

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f5f4f2 !important",
  },
});

export default function DemoExerciseEditor({
  exerciseCode,
  setExerciseCode,
  solutionLocation,
}) {

    const lastFrom = React.useRef(solutionLocation.start);
  
    function setLastFrom(value) {
      lastFrom.current = value;
    };

    const lastTo = React.useRef(solutionLocation.end);
  
    function setLastTo(value) {
      lastTo.current = value;
    };

  const classes = useStyles();

  const addUnderline = StateEffect.define();

  const ref = React.useRef();

  const underlineField = StateField.define({
    create() {
      return Decoration.none;
    },
    update(underlines, tr) {

      underlines = underlines.map(tr.changes);
      for (let e of tr.effects)
        if (e.is(addUnderline)) {
          underlines = underlines.update({
            add: [underlineMark.range(e.value.from, e.value.to)],
          });
          //underlines = underlines.update({add: e.value, sort: true})
        }
      return underlines;
    },
    provide: (f) => EditorView.decorations.from(f),
  });

  const underlineMark = Decoration.mark({
    attributes: { style: "background-color: #a5a5aa" },
    //class: "cm-selectionBackground"
  });

  const underlineSelection = () => {

    let from = solutionLocation.start;
    let to = solutionLocation.end - solutionLocation.start + 1;
    let line = solutionLocation.line - 1;

    if (line < 0) return false;

    ref.current.view.state.doc.text.forEach((item, index) => {
      if (line > index) {
        from = from + item.length + 1;
      }
      if (line === index) {
        to = to + from;
      }
    });

    let effects = [addUnderline.of({ from, to })];

    if(lastFrom.current===from && lastTo.current===to) 
      return false;

    setLastFrom(from)
    setLastTo(to)

    if (!effects.length) return false;

    effects.push(StateEffect.appendConfig.of([underlineField]));

    ref.current.view.dispatch({ effects });

    return true;
  }
  
  const onChange = (value, viewUpdate) => {
    const state = viewUpdate.state.toJSON(stateFields);
    setExerciseCode(state.doc);
  };

  return (
    <Box sx={{ margin: 2 }} style={{ height: "96%" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item md={16} xs={6} style={{ height: "66%" }}>
          <Paper
            className={classes.root}
            elevation={0}
            variant="outlined"
            square
            sx={{ margin: 2 }}
          >
            <CodeMirror
              ref={ref}
              value={exerciseCode}
              readOnly={false}
              onChange={onChange}
              minHeight={"calc(41vh)"}
              maxHeight={"calc(41vh)"}
              extensions={[
                indentOnInput(),
                java({ jsx: true }),
                underlineField
              ]}
              onUpdate={underlineSelection}
            />
          </Paper>
        </Grid>
        <Grid item md={16} xs={6} style={{ height: "28%" }}>
          <Paper sx={{ margin: 2 }}>
            <Typography
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
            >
              See it working.
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              In this demonstration page you can provide a reference solution (left), and
              experiment the learners' perspective as hint consumers (right).
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
