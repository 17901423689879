import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Exercises } from "../../services";
import Skeleton from "@mui/material/Skeleton";
import Grow from "@mui/material/Grow";

function ExerciseList() {
  const [exercises, setExercises] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    Exercises.getAll().then((response) => {
      setExercises(response.data);
      setLoading(false);
    });
  }, []);

  return isLoading ? loadingElements() : loadedElements();

  function loadedElements() {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="stretch"
        rowSpacing={1} 
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {exercises &&
          exercises.map((exercise, index) => (
            <Grid key={exercise.id} xs={6} item>
              <Card>
                <Grow in={true}>
                  <CardActionArea
                    component={RouterLink}
                    to={`/exercise/${exercise.id}`}
                  >
                    <CardContent>
                      <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
                        {exercise.name}
                      </Typography>
                      <Typography variant="body2">
                        {exercise.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Grow>
              </Card>
            </Grid>
          ))}
      </Grid>
    );
  }

  function loadingElements() {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {Array.from(new Array(4)).map((item, index) => loadingElement())}
      </Grid>
    );

    function loadingElement() {
      return (
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
                <Skeleton />
              </Typography>
              <Typography variant="body2">
                <Skeleton />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }
  }
}

export default function Home() {
  const minMaxHeight = "calc(75vh)";
  return (
    <Box sx={{ margin: 2, maxHeight: minMaxHeight, minHeight: minMaxHeight }}>
      <ExerciseList />
    </Box>
  );
}
