import hintApiInstance from "./hintApiConfig";
const hints = '/hints'

export const Hints = {
    getAll: function(submissionId, solutionId, code) {
        return hintApiInstance
            .post(
                hints,
                {
                    solutionId: submissionId,
                    submissionId: solutionId,
                    code: code
                }
            );
    }
}