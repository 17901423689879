import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
root: {
	flexGrow: 1
},
menuButton: {
	marginRight: theme.spacing(2),
},
}));

export default function Navbar() {
const classes = useStyles();

return (
	<AppBar className={classes.root} position="static" >
		<Toolbar variant="dense" style={{ backgroundColor: "#EBEAF4"}}>
		  	<Typography variant="h2">
				<Link to="/">
					<img src="../assets/logo.png" alt="bug" height={100} />
				</Link>
      		</Typography>

			<a href="https://www.iscte-iul.pt" target="_blank" rel="noreferrer"><img src="../assets/iscte.png" alt="iscte" height={100} /></a>
		</Toolbar>
	</AppBar>
);
}
