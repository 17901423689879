import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      {
        <Link color="inherit" href="https://www.iscte-iul.pt/">
          Iscte - Instituto Universitario de Lisboa
        </Link>
      }{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{ p: 6 }}
      style={{
        position: "fixed",
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "#EBEAF4",
        maxHeight: "12vh",
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="body1">
          Jinter: A Hint Generation System for Java Exercises
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {"Demonstration of the tool paper presented at "}
          {
            <Link
              color="inherit"
              href="https://iticse.acm.org/2023/"
              target="_blank"
              rel="noreferrer"
            >
              ITiCSE 2023
            </Link>
          }
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {"Copyright © "}
          {
            <Link
              color="inherit"
              href="http://linkedin.com/in/jorge-gon%C3%A7alves-77b56380"
              target="_blank"
              rel="noreferrer"
            >
              Jorge A. Gonçalves
            </Link>
          }
          {", "}
          {
            <Link
              color="inherit"
              href="https://andre-santos-pt.github.io"
              target="_blank"
              rel="noreferrer"
            >
              André L. Santos
            </Link>
          }
        </Typography>
      </Container>
    </Box>
  );
}
