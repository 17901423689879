import hintApiInstance from "./hintApiConfig";
const compiler = '/compiler'

export const Compiler = {
    compile: function() {
        return hintApiInstance.get(compiler); 
    },
    compileByExerciseId: function(id) {
        return hintApiInstance.get(`${compiler}/${id}`);  
    },
    test: function(submissionId, solutionId, code, testData) {
        return hintApiInstance
            .post(
                `${compiler}/${solutionId}/test`,
                {
                    id: submissionId,
                    code: code,
                    testData: testData
                }
            ); 
    }
}
