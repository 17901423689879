import * as React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const useStyles = makeStyles({
  root: {
    textAlign: "left",
    backgroundColor: "#f5f4f2 !important"
  }
});

const CompilerTypography = styled((props) => <Typography {...props} />)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: 'monospace'
}));

function CompilerOutput(props) {
  const { children } = props;
    
  return (
    <div role="tabpanel">
      <CompilerTypography  color = "#2B2B2B">{children}</CompilerTypography>
    </div>
  );
}

function CompilerErrorOutput(props) {
  const { children } = props;
    
  return (
    <div role="tabpanel">
      <CompilerTypography  color = "#ff0000">{children}</CompilerTypography>
    </div>
  );
}

CompilerOutput.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function TestResults(props) {
  const minMaxHeight = 'calc(15vh)';
  const classes = useStyles();

  return ( 
    <Box style={{height: '28%'}}>
        <Paper  className={classes.root} elevation={0} variant="outlined" square sx={{ minHeight: minMaxHeight }} >
        {props.testsResults && props.testsResults.map((testResult, index) =>
        testResult.result.wasSuccessful
            ? (<CompilerOutput >
                {`${testResult.result.hasPassed ? '✅' : '🛑'} Test${index + 1} -> Input: ${testResult.result.input && testResult.result.input.map((value, index) =>`${index === 0 ? "" : ","} par${index} : ${value}`)} -> Output: ${testResult.result.output}`}
            </CompilerOutput>)
            :(<CompilerErrorOutput >
                {testResult.result.input
                  ? `${'🛑'} Test${index + 1} -> Input: ${testResult.result.input && testResult.result.input.map((value, index) =>`${index === 0 ? "" : ","} par${index} : ${value}`)} -> Error Output: $${testResult.result.errors && testResult.result.errors.map((error, index) =>`${index === 0 ? "" : ","} ${error}`)}`
                  : `An unexpected error ocurred: $${testResult.result.errors && testResult.result.errors.map((error, index) =>`${index === 0 ? "" : ","} ${error}`)}`
                }
            </CompilerErrorOutput>))}  
        </Paper>
    </Box>
  );
}