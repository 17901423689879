import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DemoCodeEditor from "../../components/DemoCodeEditor";
import DemoExerciseEditor from "../../components/DemoExerciseEditor";
import Grow from "@mui/material/Grow";

export default function DemoArea() {
  const [solutionLocation, setSolutionLocation] = useState({
    line: 0,
    start: 0,
    end: 0,
  });
  const [exerciseCode, setExerciseCode] = useState(
    "class Exercise1 {\r\n        static int max(int[] array) {\r\n            int m = array[0];\r\n            for (int i = 1; i < array.length; i++) {\r\n                if (m < array[i]) {\r\n                    m = array[i];\r\n                }\r\n            }\r\n            return m;\r\n        }\r\n        \r\n        \r\n    }"
  );
  
  return (
    <Box sx={{ flexGrow: 1 }} style={{ height: "96%" }}>
      <Grid container spacing={0}>
        <Grow
          in={true}
          style={{ transformOrigin: "0 0 0" }}
          {...{ timeout: 1000 }}
        >
          <Grid item md={6} xs={6}>
            <DemoExerciseEditor
              exerciseCode={exerciseCode}
              setExerciseCode={setExerciseCode}
              solutionLocation={solutionLocation}
            />
          </Grid>
        </Grow>
        <Grow
          in={true}
          style={{ transformOrigin: "0 0 0" }}
          {...{ timeout: 1000 }}
        >
          <Grid item md={6} xs={6}>
            <DemoCodeEditor
              exerciseCode={exerciseCode}
              setSolutionLocation={setSolutionLocation}
            />
          </Grid>
        </Grow>
      </Grid>
    </Box>
  );
}
