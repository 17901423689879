import React, { useEffect, useCallback, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import Box from "@mui/material/Box";
import { java } from "@codemirror/lang-java";
import { historyField } from "@codemirror/commands";
import { linter, lintGutter } from "@codemirror/lint";
import { indentOnInput } from "@codemirror/language";
import { Compiler, Exercises, Hints } from "../../services";
import LoadingButton from "@mui/lab/LoadingButton";
import { PlayCircleOutlineRounded, TipsAndUpdatesOutlined} from "@mui/icons-material";
import { Paper } from "@mui/material";
import TestsResults from "../TestsResults";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const stateFields = { history: historyField };

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f5f4f2 !important",
  },
});

export default function CodeEditor(props) {
  const [code, setCode] = useState("class Solution {\n\n}");
  const [hints, setHints] = useState([]);
  const [testData, setTestData] = useState([]);
  const [isSubmitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [isHintBtnLoading, setHintBtnLoading] = useState(false);
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [isHintBtnDisabled, setHintBtnDisabled] = useState(true);
  const [testDataResult, setTestDataResult] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setSubmitBtnDisabled(true);
    setHintBtnDisabled(true);
    Exercises.getById(props.id)
      .then((response) => {
        setTestData(response.data.testData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSubmitBtnDisabled(false);
        setHintBtnDisabled(false);
      });
  }, [props.id]);

  const onClickHint = useCallback(() => {
    setHintBtnLoading(true);
    setSubmitBtnDisabled(true);
    setTestDataResult([]);
    Hints.getAll(props.id, props.id, code)
      .then((response) => {
        setHints(mapHints(response.data.hints));
      })
      .catch((error) => {
        setTestDataResult([
          {
            result: {
              wasSuccessful: false,
              errors: [error.message],
            },
          },
        ]);
      })
      .finally(() => {
        setHintBtnLoading(false);
        setSubmitBtnDisabled(false);
      });
  }, [code, props.id]);

  const onClickSubmit = useCallback(() => {
    setSubmitBtnLoading(true);
    setHintBtnDisabled(true);
    setTestDataResult([]);
    Compiler.test(props.id, props.id, code, testData)
      .then((response) => {
        setTestDataResult(response.data);
      })
      .catch((error) => {
        setTestDataResult([
          {
            result: {
              wasSuccessful: false,
              errors: [error.message],
            },
          },
        ]);
      })
      .finally(() => {
        setSubmitBtnLoading(false);
        setHintBtnDisabled(false);
      });
  }, [code, props.id, testData]);

  const onChange = (value, viewUpdate) => {
    const state = viewUpdate.state.toJSON(stateFields);
    setCode(state.doc);
    setHints([]);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Box sx={{ margin: 2}} style={{height: '96%'}}>
      <Grid container 
          direction="column"
          justifyContent="center"
          alignItems="stretch"
        >
        <Grid item md={16} xs={6} style={{height: '66%'}}>
        <Paper
          className={classes.root}
          elevation={0}
          variant="outlined"
          square
          sx={{ margin: 2}}
        >
            <CodeMirror
              value={code}
              readOnly={isHintBtnLoading || isSubmitBtnLoading}
              onChange={onChange}
              minHeight={"calc(41vh)"}
              maxHeight={"calc(41vh)"}
              extensions={[
                linter((view) => linterSetup(view)),
                lintGutter(),
                indentOnInput(),
                java({ jsx: true }),
              ]}
              // theme={darcula}
            />
          <Box align={"right"} sx={{ p: 2, border: 0, borderColor: "grey.500" }}>
            <LoadingButton
              size="medium"
              sx={{ margin: 0.5 }}
              onClick={onClickHint}
              loading={isHintBtnLoading}
              disabled={isHintBtnDisabled}
              variant="outlined"
              startIcon={<TipsAndUpdatesOutlined />}
            >
              Get Hint!
            </LoadingButton>
            <LoadingButton
              size="medium"
              sx={{ margin: 0.5 }}
              onClick={onClickSubmit}
              loading={isSubmitBtnLoading}
              disabled={isSubmitBtnDisabled}
              variant="outlined"
              startIcon={<PlayCircleOutlineRounded />}
            >
              Submit
            </LoadingButton>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={16} xs={6} style={{height: '28%'}}>
      <Paper
          className={classes.root}
          elevation={0}
          variant="outlined"
          square
          sx={{ margin: 2}}
        >
      <TestsResults testsResults={testDataResult} />
      </Paper>
      </Grid>
      </Grid>
    </Box>
  );

  function mapHints(hints) {
    let mappedHints = [];
    hints.forEach((item) => {
      if (item.submissionLocation != null)
        mappedHints.push({
          message: `${item.hintDefinition.isPositiveFeedback ? "✔️" : "💡"}${
            item.hintDefinition.message
          }${
            item.hintDefinition.complementaryMessage != null
              ? `\n\n📖 ${item.hintDefinition.complementaryMessage}`
              : ""
          }`,
          location: item.hintDefinition.isPositiveFeedback
            ? { line: item.submissionLocation.line, start: 0, end: -1 }
            : item.submissionLocation,
          severity: item.hintDefinition.isPositiveFeedback ? "info" : "warning",
        });
    });
    return mappedHints;
  }

  function linterSetup(view) {
    return mapDiagnostic(hints, view.state.doc);
  }

  function mapDiagnostic(hints, doc) {
    let diagnostics = [];
    hints.forEach((item) => {
      if (item.location != null)
        diagnostics.push({
          message: item.message,
          severity: item.severity,
          from: doc.line(item.location.line).from + item.location.start,
          to:
            item.location.end === 0
              ? doc.line(item.location.line).to
              : doc.line(item.location.line).from + item.location.end + 1,
        });
    });
    return diagnostics;
  }
}
