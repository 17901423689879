import hintApiInstance from "./hintApiConfig";
const demo = '/demo'

export const DemoHints = {
    getAll: function(submissionId, solutionCode, code) {
        return hintApiInstance
            .post(
                demo,
                {
                    solutionCode: solutionCode,
                    submissionId: submissionId,
                    code: code
                }
            );
    }
}