import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/Layout/NavBar";
import ExerciseArea from "./pages/ExerciseArea";
import Home from "./pages/Home";
import DemoArea from "./pages/DemoArea";
import Footer from "./components/Layout/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";

function App() {
  
  const defaultTheme = createTheme();

  return (
    <Router>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}/>
        <CssBaseline />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/exercise/:id" element={<ExerciseArea />} />
          <Route path="/demo/" element={<DemoArea />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </Router>
  );
}
export default App;
