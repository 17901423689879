import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Exercises } from "../../services";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

export default function ExerciseContent(props) {
  const [exercise, setExercise] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    Exercises.getById(props.id).then((response) => {
      setExercise(response.data);
      setLoading(false);
    });
  }, [props.id]);

  return (
    <Box sx={{flexGrow: 1}} style={{height: '96%'}}>
    <Card sx={{ margin: 4}} style={{height: '96%'}}>
      <CardContent  >
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
          {isLoading ? <Skeleton /> : exercise.name}
        </Typography>
        <Typography variant="body2">
          {isLoading ? <Skeleton /> : exercise.description}
        </Typography>
        <br />
        <Typography variant="body2">
          {isLoading ? <Skeleton /> : exercise.additionalDescription}
        </Typography>
        <br />
        {(isLoading ? Array(2) : exercise.testData).map((testData, index) => (
          <div>
            <Typography variant="h7" component="div">
              {isLoading ? <Skeleton /> : "Input:"}
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {isLoading ? (
                <Skeleton />
              ) : (
                testData.input &&
                testData.input.map(
                  (input, index) =>
                    `${index === 0 ? "" : ","} ${input.value}`
                )
              )}
            </Typography>
            <Typography variant="h7" component="div">
              {isLoading ? <Skeleton /> : "Output:"}
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {isLoading ? <Skeleton /> : testData.output.value}
            </Typography>
          </div>
        ))}
      </CardContent>
    </Card>
    </Box>
  );
}
