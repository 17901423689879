import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CodeEditor from "../../components/CodeEditor";
import ExerciseContent from "../../components/ExerciseContent";
import { useParams } from "react-router-dom";
import Grow from "@mui/material/Grow";

export default function ExerciseArea(props) {
  const { id } = useParams();
  return (
    <Box sx={{flexGrow: 1}} style={{height: '96%'}}>
      <Grid container spacing={0}>
        <Grow in={true}>
          <Grid item md={4} xs={6}>
            <ExerciseContent id={id} />
          </Grid>
        </Grow>
        <Grow
          in={true}
          style={{ transformOrigin: "0 0 0" }}
          {...{ timeout: 1000 }}
        >
          <Grid item md={8} xs={6}>
            <CodeEditor id={id} />
          </Grid>
        </Grow>
      </Grid>
    </Box>
  );
}
