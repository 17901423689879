import hintApiInstance from "./hintApiConfig";
const exercises = '/exercises'

export const Exercises = {
    getAll: function() {
        return hintApiInstance.get(exercises); 
    },
    getById: function(id) {
        return hintApiInstance.get(`${exercises}/${id}`);  
    }
}
